import { ReactNode } from 'react';
import styles from './index.module.css';

import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Icon,
  chakra,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
// import Icon from '@ant-design/icons';
import Logo from 'assets/svg/logo-without-text.svg';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { ProfileContext } from 'components/context/ProfileProvider';
import { default as NextLink } from 'next/link';
import RightMenu from './RightMenu';

const NavLink = ({ children, href }: { children: ReactNode; href: string }) => (
  <NextLink href={href} passHref>
    <Link
      px={2}
      py={1}
      rounded={'lg'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href={'#'}
      colorScheme="brand"
    >
      <chakra.h1 textTransform="uppercase">{children}</chakra.h1>
    </Link>
  </NextLink>
);

export const Header = ({ admin }: { admin?: boolean }): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formatMessage } = useIntl();
  const { hikes } = useContext(ProfileContext);

  const links = [
    <NavLink key="/hitch/custom" href={`/hitch/custom`}>
      {formatMessage({
        defaultMessage: 'Custom',
        description: 'menu/link/custom',
      })}
    </NavLink>,
    <NavLink key="/hitch/off-the-shelf" href={`/hitch/off-the-shelf`}>
      {formatMessage({
        defaultMessage: 'Off the shelve',
        description: 'menu/link/off-the-shelve',
      })}
    </NavLink>,
    <NavLink key="/contact" href={`/contact`}>
      {formatMessage({
        defaultMessage: 'Contact',
        description: 'menu/link/contact',
      })}
    </NavLink>,
    hikes && hikes.length > 0 ? (
      <NavLink key="dashboard" href={`/dashboard`}>
        {formatMessage({
          defaultMessage: 'Dashboard',
          description: 'menu/link/dashboard',
        })}
      </NavLink>
    ) : (
      <NavLink key="pricing" href={`/pricing`}>
        {formatMessage({
          defaultMessage: 'Pricing',
          description: 'menu/link/pricing',
        })}
      </NavLink>
    ),
  ];

  return (
    <>
      <Box px={4} borderBottomWidth={1} borderBottomColor={'gray.100'}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'lg'}
            colorScheme={'brand'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ lg: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <NextLink href={`/`} passHref>
              <Link className={styles.newLogo}>
                <Icon as={Logo} style={{ fontSize: '75px' }} />
              </Link>
            </NextLink>
            <NextLink href={`/`} passHref>
              <Link className={styles.logoText}>
                <h2>HITCH AND HIKE</h2>
              </Link>
            </NextLink>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', lg: 'flex' }}>
              {links.map((linkComponent) => linkComponent)}
            </HStack>
          </HStack>
          <RightMenu admin={admin} />
        </Flex>

        {isOpen ? (
          <Box width="100vw" height="100vh" pb={4} display={{ lg: 'none' }}>
            <Stack as={'nav'} spacing={10}>
              {links.map((linkComponent) => linkComponent)}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
