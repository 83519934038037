import Wrapper from 'components/public/Wrapper';
import React, { useContext } from 'react';
import { GOOGLE_ONE_TAP_CONTAINER } from '../../private/Login/OneTapContainer/useGoogleOneTap';
import styles from '../../private/Login/OneTapContainer/OneTapContainer.module.css';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';

export type PublicWrapperProps = {
  f: (translationKey: string) => string;
};
// https://react-typescript-cheatsheet.netlify.app/docs/hoc/react_hoc_docs/
export function withPublicWrapper<P>(
  Component: React.ComponentType<P>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { withSider }: { withSider?: boolean; withGoogleOneTap?: boolean } = {
    withSider: false,
  }
): React.FC<P> {
  const WrappedComponent: React.FC<P> = (props) => {
    // useGoogleOneTap(withGoogleOneTap);
    const { admin } = useContext(UserAuthenticationContext);

    return (
      <Wrapper withSider={withSider} admin={admin}>
        {/* we can make One-Tap attach to a custom element
      - see 'prompt_parent_id' in useGoogleOneTap.ts */}
        <div className={styles.oneTapContainer} id={GOOGLE_ONE_TAP_CONTAINER} />
        <Component {...props} />
      </Wrapper>
    );
  };

  WrappedComponent.displayName = `withPublicWrapper(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}

function getDisplayName<T>(WrappedComponent: React.ComponentType<T>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPublicWrapper;
