import { useState, useContext, useEffect } from 'react';
import firebase from 'firebase/app';
import {
  isUserLoggedIn,
  UserAuthenticationContext,
} from 'components/context/UserAuthenticationProvider';
import * as Sentry from '@sentry/node';
import { CookieConsentContext } from 'components/context/CookieConsentProvider';
import useScript from 'components/utils/hooks/useScript';

export const GOOGLE_ONE_TAP_CONTAINER = 'hitch-and-hike-one-tap-container';

export const useGoogleOneTap = (withGoogleOneTap?: boolean): { isActive: boolean } => {
  const [id_token, setId_token] = useState<string>();
  const { user, authLoading } = useContext(UserAuthenticationContext);
  const { personalSiteAllowed } = useContext(CookieConsentContext);

  const isActive = !!(withGoogleOneTap && personalSiteAllowed);
  const { status } = useScript(isActive ? 'https://accounts.google.com/gsi/client' : undefined);

  useEffect(() => {
    if (!isActive && status !== 'ready') {
      return;
    }

    if (!isUserLoggedIn(user) && !authLoading && window.google) {
      const handleCredentialResponse = (response: { credential?: string }): void => {
        setId_token(response.credential);
      };

      const client_id = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

      try {
        window?.google?.accounts?.id?.initialize({
          client_id,
          callback: handleCredentialResponse,
          auto_select: true,
          context: 'use',
          // native_callback: (): void => console.log('google native callback'),
          cancel_on_tap_outside: false,
          prompt_parent_id: GOOGLE_ONE_TAP_CONTAINER,
        });
        window?.google?.accounts?.id?.prompt((notification: NotificationProps) => {
          console.log('notification is: ', notification.getMomentType());
          // if (notification.isDisplayMoment()) {
          // }

          if (notification.isNotDisplayed()) {
            console.warn('one-tap did not show because:', notification.getNotDisplayedReason());
          }
          if (notification.isSkippedMoment()) {
            console.warn('one-tap skipped because:', notification.getSkippedReason());
          }
          if (notification.isDismissedMoment()) {
            console.warn('one-tap dismissed because:', notification.getDismissedReason());
            // if (notification.getDismissedReason() !== 'credential_returned') {
            // }
          }
        });
      } catch (e) {
        // TODO track those errors in Crashlytics
        console.error(e);
        Sentry.captureException(e);
      }
    } else if (typeof window.google !== 'undefined') {
      window.google.accounts.id.cancel();
    }
  }, [authLoading, user, withGoogleOneTap, personalSiteAllowed, status, isActive]);

  useEffect(() => {
    if (!withGoogleOneTap) {
      return;
    }
    async function login(): Promise<void> {
      // Sign in with credential from the Google user.
      try {
        if (isUserLoggedIn()) {
          setId_token(undefined);
          return;
        }
        if (!id_token) {
          console.error('useGoogleOneTap login attempt without idToken');
          Sentry.captureException('useGoogleOneTap login attempt without idToken');
          return;
        }
        const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
        if (user) {
          await user.linkWithCredential(credential);
        } else {
          await firebase.auth().signInWithCredential(credential);
        }
        setId_token(undefined);
      } catch (e) {
        if (e?.code !== 'auth/credential-already-in-use') {
          throw e;
        }
        if (!e?.credential?.idToken) {
          throw e;
        }
        try {
          await firebase.auth().signInWithCredential(e.credential);
        } catch (err) {
          console.error(err);
          Sentry.captureException(err);
        }
      }
    }

    if (id_token && !user && !authLoading) {
      login();
    }
  }, [id_token, user, withGoogleOneTap, authLoading]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        window.google?.accounts.id.disableAutoSelect();
      }
    });
  }, []);

  return { isActive };
};
