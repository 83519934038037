import React from 'react';
import { Layout } from 'antd';
import { CSSProperties } from 'react';

const { Content } = Layout;

const PublicContent = ({
  withSider,
  headerHeight,
  siderWidth,
  isCollapsed,
  children,
}: React.PropsWithChildren<{
  withSider?: boolean;
  headerHeight?: string;
  siderWidth?: number;
  isCollapsed?: boolean;
}>): JSX.Element => {
  const contentStyle: CSSProperties = {
    marginTop: headerHeight,
  };

  if (withSider) {
    contentStyle.padding = 24;
  }

  if (!isCollapsed) {
    contentStyle.marginLeft = siderWidth;
  } else {
    contentStyle.marginLeft = 0;
  }

  return <Content style={contentStyle}>{children}</Content>;
};

export default PublicContent;
