import React from 'react';
import { Layout } from 'antd';
import Footer from '../Footer';
import Content from '../Content';
import styles from './index.module.css';
import { Header } from '../Header';
// import { useState } from 'react';
// import Sider from '../Sider';

const HEADER_HEIGHT = '50px';
const SIDER_WIDTH = 200;

const Wrapper = ({
  children,
  withSider,
  admin,
}: React.PropsWithChildren<{ withSider?: boolean; admin?: boolean }>): JSX.Element => {
  // const [isCollapsed, setCollapsed] = useState<boolean>(false);
  return (
    <Layout className={styles.publicWrapper}>
      <Header admin={admin} />

      {withSider ? (
        <Layout style={{ marginTop: HEADER_HEIGHT }}>
          <Content withSider siderWidth={SIDER_WIDTH} isCollapsed>
            {children}
          </Content>
        </Layout>
      ) : (
        <Content headerHeight={HEADER_HEIGHT}>{children}</Content>
      )}

      <Footer />
    </Layout>
  );
};

export default Wrapper;
