import {
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext } from 'react';
import firebase from 'firebase/app';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';
import { useRouter } from 'next/router';
import { UserOutlined } from '@ant-design/icons';

const LanguageButton = (): JSX.Element => {
  const { locale, push, pathname, query } = useRouter();
  const languageToToggle = locale === 'en' ? 'nl' : 'en';

  return (
    <Button
      variant={'outline'}
      colorScheme={'brand'}
      size={'sm'}
      mr={4}
      onClick={() =>
        push(
          {
            pathname,
            query,
          },
          undefined,
          { locale: languageToToggle }
        )
      }
    >
      {languageToToggle === 'en' && (
        <FormattedMessage defaultMessage="English" description="toggle to English" />
      )}
      {languageToToggle === 'nl' && (
        <FormattedMessage defaultMessage="Nederlands" description="toggle to Dutch" />
      )}
    </Button>
  );
};

const RightMenu = ({ admin }: { admin?: boolean }): JSX.Element => {
  const { user } = useContext(UserAuthenticationContext) as { user?: firebase.User };
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const [isSmallerThen500px] = useMediaQuery(['(max-width: 500px)']);

  const logoutButton = (
    <Button
      variant={'solid'}
      colorScheme={'brand'}
      size={'sm'}
      mr={4}
      onClick={() => firebase.auth().signOut()}
    >
      {formatMessage({
        defaultMessage: 'Logout',
        description: 'menu/link/logout',
      })}
    </Button>
  );

  const adminItem = (
    <MenuItem
      key="admin"
      onClick={() => {
        push('/admin');
      }}
    >
      Admin
    </MenuItem>
  );
  const logoutMenuItem = (
    <MenuItem key="logout" onClick={() => firebase.auth().signOut()}>
      {formatMessage({
        defaultMessage: 'Logout',
        description: 'menu/link/logout',
      })}
    </MenuItem>
  );

  const loginMenuItem = (
    <MenuItem key="login" onClick={() => push('/login')}>
      {formatMessage({
        defaultMessage: 'Login',
        description: 'menu/link/login',
      })}
    </MenuItem>
  );

  const languageMenuItem = (
    <>
      <MenuDivider />
      <LanguageButton />
    </>
  );

  const publicMenu = isSmallerThen500px ? (
    <Menu>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        minW={0}
        colorScheme={'brand'}
      >
        <Avatar icon={<UserOutlined twoToneColor="#7ea172" />} size={'sm'} />
      </MenuButton>
      <MenuList>
        {isSmallerThen500px && languageMenuItem}
        <MenuDivider />
        {loginMenuItem}
      </MenuList>
    </Menu>
  ) : (
    <>
      <LanguageButton />
      <Button
        variant={'solid'}
        colorScheme={'brand'}
        size={'sm'}
        mr={4}
        onClick={() => push('/login')}
      >
        {formatMessage({
          defaultMessage: 'Login',
          description: 'menu/link/login',
        })}
      </Button>
    </>
  );

  const authenticatedMenu =
    isSmallerThen500px || admin ? (
      <>
        {!isSmallerThen500px && <LanguageButton />}
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}
            colorScheme={'brand'}
          >
            <Avatar icon={<UserOutlined twoToneColor="#7ea172" />} size={'sm'} />
          </MenuButton>
          <MenuList>
            {admin && adminItem}
            {isSmallerThen500px && languageMenuItem}
            <MenuDivider />
            {logoutMenuItem}
          </MenuList>
        </Menu>
      </>
    ) : (
      <>
        <LanguageButton />
        {logoutButton}
      </>
    );

  return (
    <Flex alignItems={'center'}>
      {user && user.uid && !user.isAnonymous ? authenticatedMenu : publicMenu}
    </Flex>
  );
};
export default RightMenu;
