import { BookOutlined, MailOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Divider } from 'antd';
import Link from 'next/link';
import React from 'react';
const { Footer } = Layout;

const PublicFooter = () => (
  <Footer style={{ textAlign: 'center', backgroundColor: 'white', padding: '50px 0' }}>
    <Row align="middle" justify="center" gutter={[32, 16]}>
      <Col span={24}>
        <Link href="/">Hitch and Hike ©2021</Link>
      </Col>
      <Divider />
      <Col>
        <Row gutter={8}>
          <Col>
            <BookOutlined />
          </Col>
          <Col>BE0761.743.968</Col>
        </Row>
      </Col>
      <Col>
        <Row gutter={8}>
          <Col>
            <MailOutlined />
          </Col>
          <Col>
            <Link href="mailto:hello@hitchandhike.com">hello@hitchandhike.com</Link>
          </Col>
        </Row>
      </Col>
      <Col>
        <Link href="/terms-of-service">Terms of Service</Link>
      </Col>
      <Col>
        <Link href="/privacy-policy">Privacy Policy</Link>
      </Col>
    </Row>
  </Footer>
);

export default PublicFooter;
